import { API_ENDPOINTS } from "api/apiEndpoints";
import axiosInstance from "api/axiosConfig";

//Get Business Token
export const getBusinessToken = async (data) => {
    try {

        console.log(data);
        

        const response = await axiosInstance.post(API_ENDPOINTS.GET_BUSINESS_TOKEN(),data);
        return response;
    }
    catch (error) {
        // throw error;
        console.log(error);
        return error;
    }
}





